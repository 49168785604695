// https://github.com/jonathantneal/svg4everybody
// https://github.com/bfred-it/object-fit-images
svg4everybody();
var objectFitClass = document.querySelectorAll("img.object-fit--cover");

document.addEventListener("DOMContentLoaded", function(event) {
    objectFitImages(objectFitClass);
});
document.addEventListener("orientationchange", function(event){
    objectFitImages(objectFitClass);

});
